export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [0];

export const dictionary = {
		"/": [4],
		"/callback/ciam": [~27],
		"/[country=any_country]/[language=any_language]/_crp_": [6,[2]],
		"/[country=any_country]/[language=any_language]/_crp_/email": [7,[2]],
		"/[country=any_country]/[language=any_language]/_crp_/logout": [8,[2]],
		"/[country=any_country]/[language=any_language]/builder-preview/[modelName]/[publicKey]/[contentId]": [~9],
		"/[country=any_country]/[language=any_language]/builder-section-preview/[apiKey]/[model_name]/[[entry_id]]": [~10],
		"/[country=any_country]/[language=any_language]/digital-entitlement": [11,[3]],
		"/[country=any_country]/[language=any_language]/digital-entitlement/activate-code": [12,[3]],
		"/[country=any_country]/[language=any_language]/digital-entitlement/contact-address-info": [~13,[3]],
		"/[country=any_country]/[language=any_language]/digital-entitlement/redeem-service-code": [14,[3]],
		"/[country=any_country]/[language=any_language]/insights": [15],
		"/[country=any_country]/[language=any_language]/insights/page/[...catchall]": [19],
		"/[country=any_country]/[language=any_language]/insights/post/[...rest]": [~20],
		"/[country=any_country]/[language=any_language]/insights/[category]": [16],
		"/[country=any_country]/[language=any_language]/insights/[category]/[sub_category]": [17],
		"/[country=any_country]/[language=any_language]/insights/[category]/[sub_category]/[post]": [18],
		"/[country=any_country]/[language=any_language]/schneider-in-america": [21],
		"/[country=any_country]/[language=any_language]/techcomm-pdf-symbol/[...catchall]": [23],
		"/[country=any_country]/[language=any_language]/techcomm-pdf/[...catchall]": [22],
		"/[country=any_country]/[language=any_language]/techcomm-web-symbol/[...catchall]": [25],
		"/[country=any_country]/[language=any_language]/techcomm-web/[...catchall]": [24],
		"/[country=any_country]/[language=any_language]/work/campaign/local/impact-maker/[...catchall]": [26],
		"/[country=any_country]/[language=any_language]/[...catchall]/[...catchall]": [~5]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';